import React from "react";

import { Outlet } from "react-router-dom";
import Header from "../../views/home/shared/header";
import SideMenu from "../../views/home/shared/sideMenu";

function HomeLayout() {
  return (
    <div className={"text-darkGray h-100"}>
      <Header />
      <div className="main-container gap-4 m-15p">
        <div className="calc-h-100 rounded-4 position-relative">
          <SideMenu />
        </div>
        <main className="calc-h-100  bg-white rounded-4 overflow-auto">
          {" "}
          {/* TODO: find a way to se this dynamically from children bg-white rounded-4 */}
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default HomeLayout;
