import MultiDatePicker, { DateObject } from "react-multi-date-picker";
import HijriCalendar from "react-date-object/calendars/arabic";
import GregorianCalendar from "react-date-object/calendars/gregorian";
import ArabicCalendar from "react-date-object/locales/arabic_ar";
import "./DatePicker.scss";
import { useCookieContext } from "contexts";

type DatePickerProps = {
  label: string;
  placeholder?: string;
  value?: string | Date | DateObject;
  onChange?: (date: Date | null) => void;
  wrapperClassName?: string;
};

// TODO: Icon positioning and Coloring (.icon-tabler-calendar-event)
const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { label, placeholder, value, onChange, wrapperClassName } = props;

  const { isAr } = useCookieContext();

  const onLocalChange = (e: DateObject | null) => {
    onChange?.(e?.toDate() ?? null);
  };

  return (
    <div className={`date-picker-wrapper ${wrapperClassName} position-relative`}>
      <label className="mb-1">{label}</label>
      <MultiDatePicker
        placeholder={placeholder ?? label}
        value={value}
        inputClass="form-control"
        containerClassName="w-100"
        inputMode="input"
        calendar={isAr ? HijriCalendar : GregorianCalendar}
        onChange={onLocalChange}
        locale={isAr ? ArabicCalendar : undefined}
      />
      <span className="icon-calendar text-primary fs-4" />
    </div>
  );
};

export default DatePicker;
