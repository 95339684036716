import ApplicantInfo from "./components/ApplicantInfo";
import Updates from "./components/Updates";
import AchievementFile from "./components/AchievementFile";
import FundingTerms from "./components/FundingTerms";
import ResearchesCenter from "./components/ResearchesCenter";
import Procedures from "./components/Procedures";
import { useEffect, useState } from "react";
import { OrdersService } from "services/orders";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { useTranslation } from "react-i18next";
import { useNotification } from "hooks/useNotification";
import LoadingOverlay from "components/blocks/LoadingOverlay";

export const OrderInfoTab = ({ orderId }: { orderId: number }) => {
  const { t } = useTranslation("Orders");
  const { showNotification } = useNotification();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<OrderLocalItemResult>();
  const getOrderAsync = async () => {
    setLoading(true);
    try {
      const response = await OrdersService.getOneById(orderId);
      if (!response.hasError) {
        setOrder(response.data);
      } else {
        showNotification({
          description: response.description,
          type: "error",
        });
      }
    } catch (error) {
      showNotification({
        description: t("Common:internalServerError"),
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getOrderAsync();
  }, [orderId]);

  return (
    <div className="position-relative">
      <LoadingOverlay visible={loading} className="align-items-start pt-4" />
      <ApplicantInfo
        appliedUser={order?.appliedUser}
        files={order?.files}
        formData={{
          subject: order?.formSubject ?? "",
          value: order?.formValue ?? "",
        }}
      />
      <Updates />
      <AchievementFile />
      <FundingTerms />
      <ResearchesCenter />
      <Procedures />
    </div>
  );
};
