import IconButton from "components/blocks/IconButton";
import Switch from "components/blocks/Switch";
import { useTranslation } from "react-i18next";

import "./AddProgramView.scss";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import Dropdown, { DropdownItemType } from "components/blocks/Dropdown";
import { useNavigate } from "react-router-dom";
import DatePicker from "components/blocks/DatePicker";
import FileInput from "components/blocks/FileInput";
import FilePreview from "components/blocks/FileInput/FilePreview";
import RangeInput from "components/blocks/RangeInput";
import { useEffect, useState } from "react";
import { LookupService } from "services/lookupService";
import { useForm } from "react-hook-form";
import { CreateProgramRequest, createProgramSchema } from "../program.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import LookupDropdown from "components/blocks/LookupDropdown";
import { useCookieContext } from "contexts";
import ProgramTemplateEditorModal from "./ProgramTemplateEditorModal";
import FinancingCalculationItemTemplate from "./FinancingCalculationItemTemplate";
import { ProgramsService } from "services/programs";

// TODO: Extract the building blocks into components (e.g. Page Header...)
// TODO: Error handling

const now = new Date();

const AddProgramView = () => {
  // Hooks
  const { t } = useTranslation("Programs");
  const navigate = useNavigate();
  const { isRTL } = useCookieContext();

  // State
  const [fundingTypeLookups, setFundingTypeLookups] = useState<DropdownItemType[]>([]);
  const [isConditionAndRulesTemplateLoading, setIsConditionAndRulesTemplateLoading] = useState(false);
  const [isCommitmentTemplateLoading, setIsCommitmentTemplateLoading] = useState(false);
  const [conditionAndRulesIds, setConditionAndRulesIds] = useState({
    ruleId: 0,
    commitmentId: 0,
  });

  const [editorModalProps, setEditorModalProps] = useState<{
    id: number | string;
    isOpen: boolean;
    target?: "rule" | "commitment";
  }>({
    id: 0,
    isOpen: false,
    target: undefined,
  });

  // Effects
  useEffect(() => {
    const fetchFinancingTypes = async () => {
      const financingTypes = await LookupService.getAllFinancingTypes();
      setFundingTypeLookups(
        financingTypes.data.map((type) => ({
          value: type.financingTypesDetail.displayName,
          id: type.id,
          calculationWay: type.calculationWay?.calculationWayDetail?.displayName,
        })),
      );
    };

    fetchFinancingTypes();
  }, []);

  useEffect(() => {
    if (editorModalProps.id && editorModalProps.target && !editorModalProps.isOpen) {
      if (editorModalProps.target === "rule") {
        fetchConditionsAndRulesTemplate();
      } else if (editorModalProps.target === "commitment") {
        fetchCommitmentsTemplate();
      }
    }
  }, [editorModalProps.id, editorModalProps.target]);

  const fetchCommitmentsTemplate = async () => {
    setIsCommitmentTemplateLoading(true);
    const response = await ProgramsService.getPdfTemplateById(+editorModalProps.id);

    const arData = response.data.pdfTemplateDetails.find((x) => x.locale === "Ar");
    const enData = response.data.pdfTemplateDetails.find((x) => x.locale === "En");
    const content = {
      ar: arData?.templateContent ?? "",
      en: enData?.templateContent ?? "",
    };

    setValue("programDetails.0.commitmentName", arData?.displayName ?? "");
    setValue("programDetails.1.commitmentName", enData?.displayName ?? "");

    onEditorContentSave(content, "commitment");
    setEditorModalProps((prev) => ({
      ...prev,
      content,
    }));

    setIsCommitmentTemplateLoading(false);
  };

  const fetchConditionsAndRulesTemplate = async () => {
    setIsConditionAndRulesTemplateLoading(true);
    const response = await ProgramsService.getConditionAndRuleById(+editorModalProps.id);

    const arData = response.data.conditionAndRuleDetails.find((x) => x.locale === "Ar");
    const enData = response.data.conditionAndRuleDetails.find((x) => x.locale === "En");
    const content = {
      ar: arData?.templateContent ?? "",
      en: enData?.templateContent ?? "",
    };

    setValue("programDetails.0.ruleAndConditionName", arData?.displayName ?? "");
    setValue("programDetails.1.ruleAndConditionName", enData?.displayName ?? "");

    onEditorContentSave(content, "rule");
    setEditorModalProps((prev) => ({
      ...prev,
      content,
    }));

    setIsConditionAndRulesTemplateLoading(false);
  };

  useEffect(() => {
    // TODO: consider saving form state in the storage on change to prevent data loss on refresh and repopulate it on load
    return () => {
      // TODO: remove the local state from the storage
    };
  }, []);

  // Form
  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
  } = useForm<CreateProgramRequest>({
    resolver: zodResolver(createProgramSchema),
    values: {
      status: "Active",
      programTypeId: 0,
      programDetails: [
        {
          displayName: "",
          description: "",
          ruleAndConditionName: "",
          ruleAndConditionTemplate: "",
          commitmentName: "",
          commitmentTemplate: "",
          locale: "Ar",
        },
        {
          displayName: "",
          description: "",
          ruleAndConditionName: "",
          ruleAndConditionTemplate: "",
          commitmentName: "",
          commitmentTemplate: "",
          locale: "En",
        },
      ],
      classificationProgramId: 0,
      contractStartDate: now,
      fundingDurationInMonths: 0,
      adsStartDate: now,
      adsEndDate: now,
      fundingAmount: 0,
      audienceTypesIds: [],
      cycleYear: 0,
      programCycleId: 0,
      programAttachmentsIds: [],
      adsImageId: "",
      contractId: 0,
      financingCalculationItems: [],
      formBuilderSchema: "",
    },
  });

  // Callbacks
  const onFinancingCalculationItemDelete = (index: number) => {
    const terms = getValues("financingCalculationItems").filter((_, i) => i !== index);
    setValue("financingCalculationItems", terms);
  };

  const onFinancingCalculationItemAdd = (item: CreateProgramRequest["financingCalculationItems"][0]) => {
    setValue("financingCalculationItems", [...getValues("financingCalculationItems"), item]);
  };

  const onConditionsAndRulesDropdownChange = (item: DropdownItemType, target?: "rule" | "commitment") => {
    setEditorModalProps({ isOpen: false, id: item.id, target });
    setConditionAndRulesIds((prev) => ({
      ruleId: target === "rule" ? +item.id : prev.ruleId,
      commitmentId: target === "commitment" ? +item.id : prev.commitmentId,
    }));
  };

  const setEditorModalVisibleState = (isOpen: boolean, target?: "rule" | "commitment") => {
    setEditorModalProps((pre) => ({ ...pre, target: target ?? pre.target, isOpen }));
  };

  const onEditorContentSave = (content: { ar: string; en: string }, target: "rule" | "commitment") => {
    if (target === "rule") {
      setValue("programDetails.0.ruleAndConditionTemplate", content.ar);
      setValue("programDetails.1.ruleAndConditionTemplate", content.en);
    } else if (target === "commitment") {
      setValue("programDetails.0.commitmentTemplate", content.ar);
      setValue("programDetails.1.commitmentTemplate", content.en);
    }
  };

  const onSubmit = async (data: CreateProgramRequest) => {
    console.log(data);
  };

  const onShareClicked = () => {
    console.log(getValues());
  };

  console.log({ errors });

  return (
    <form className="add-program-page-wrapper" noValidate onSubmit={handleSubmit(onSubmit)}>
      {/* Page Header */}
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <div className="d-flex gap-2 align-items-center">
          <IconButton
            icon={isRTL ? "icon-full-arrow-right" : "icon-full-arrow-left"}
            innerIconColor="black"
            size="lg"
            className="text-dark"
            onClick={() => navigate(-1)}
          />
          <div className="d-flex gap-2 align-items-center">
            <h4 className="text-dark">{t("addProgram")}</h4>
            <Switch
              checked
              onChange={() => {
                // TODO: on project publish switch toggled
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <IconButton icon="icon-share" size="md" variant="light" className="text-dark" onClick={onShareClicked} />
          <button className="btn btn-primary px-3" type="submit">
            {t("Common:save")}
          </button>
        </div>
      </div>

      <div className="divider" />

      {/* Program Main Info */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("programMainInfo")} elementId={1}>
          <div className="d-flex gap-4 flex-wrap mb-3">
            <FormInput
              wrapperClassName="flex-1"
              label={t("programNameAr")}
              {...register("programDetails.0.displayName")}
            />
            <FormInput
              {...register("programDetails.0.description")}
              wrapperClassName="flex-3"
              label={t("ProgramDescAr")}
            />
          </div>
          <div className="d-flex gap-4 flex-wrap">
            <FormInput
              wrapperClassName="flex-1"
              label={t("programNameEn")}
              {...register("programDetails.1.displayName")}
            />
            <FormInput
              wrapperClassName="flex-3"
              label={t("ProgramDescEn")}
              {...register("programDetails.1.description")}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* About Program */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("aboutTheProgram")} elementId={2}>
          <div className="row">
            <LookupDropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("programType")}
              service="lookupService"
              endpoint="getAllProgramTypes"
              formProps={register("programTypeId")}
              textValueKey="programTypesDetail.displayName"
              idValueKey="id"
            />
            <LookupDropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("programClassification")}
              service="lookupService"
              endpoint="getAllProgramClassificationsByProgramTypeId"
              query={{ programTypeId: watch("programTypeId") /* based on program type ddl */ }}
              formProps={register("classificationProgramId")}
              textValueKey="programClassificationsDetail.displayName"
              idValueKey="id"
              disabled={!watch("programTypeId")}
            />

            <DatePicker
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("contractStart")}
              onChange={(date) => {
                setValue("contractStartDate", date!);
              }}
              value={watch("contractStartDate")}
            />
            <FormInput
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("fundingDuration")}
              maxLength={6}
              leftElement={<span className="text-primary">{t("months")}</span>}
              {...register("fundingDurationInMonths")}
            />
            <DatePicker
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("adsStart")}
              onChange={(date) => {
                setValue("adsStartDate", date!);
              }}
              value={watch("adsStartDate")}
            />
            <DatePicker
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("adsClose")}
              onChange={(date) => {
                setValue("adsEndDate", date!);
              }}
              value={watch("adsEndDate")}
            />
            <FormInput
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("fundingAmount")}
              leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
              {...register("fundingAmount")}
            />
            {/* TODO: multiselect */}
            <LookupDropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("targetedAudience")}
              service="lookupService"
              endpoint="getAllAudienceTypes"
              formProps={register("audienceTypesIds")}
              textValueKey="audienceTypeDetail.displayName"
              idValueKey="id"
            />
            <LookupDropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("cycleYear")}
              service="lookupService"
              endpoint="getAllProgramCycleYears"
              formProps={register("cycleYear")}
              textValueKey="programCycleYearDetail.displayName"
              idValueKey="id"
            />
            <LookupDropdown
              wrapperClassName="col-md-3 col-sm-6 mb-2"
              label={t("cycle")}
              service="lookupService"
              endpoint="getAllProgramCycleByYear"
              query={{ year: watch("cycleYear") }}
              formProps={register("programCycleId")}
              textValueKey="programCycleDetail.displayName"
              idValueKey="id"
              disabled={!watch("cycleYear")}
            />
          </div>
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Program Conditions / Rules */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("rules")} elementId={3}>
          <LookupDropdown
            wrapperClassName="col-md-3 col-sm-6 mb-2 w-100 mb-2"
            label={t("programRules")}
            service="programService"
            endpoint="getAllConditionsAndRules"
            query={{ search: "" /* TODO: server side search */ }}
            onChange={(value, item) => {
              onConditionsAndRulesDropdownChange(item, "rule");
            }}
            value={conditionAndRulesIds.ruleId}
            textValueKey="conditionAndRuleDetail.displayName"
            idValueKey="id"
            isPaginated
          />
          <FilePreview
            onShow={() => setEditorModalVisibleState(true, "rule")}
            title={watch("programDetails.0.ruleAndConditionName")}
            showButtonLabel={t("openAndEdit")}
            callbackValue="preview"
            loading={isConditionAndRulesTemplateLoading}
            disabled={!watch("programDetails.0.ruleAndConditionTemplate")}
          />

          <LookupDropdown
            wrapperClassName="col-md-3 col-sm-6 mb-2 w-100 mb-2 mt-4"
            label={t("commitment")}
            service="programService"
            endpoint="getAllPdfTemplates"
            query={{ search: "" /* TODO:  server side search */ }}
            onChange={(value, item) => {
              onConditionsAndRulesDropdownChange(item, "commitment");
            }}
            value={conditionAndRulesIds.commitmentId}
            textValueKey="pdfTemplateDetail.displayName"
            idValueKey="id"
            isPaginated
          />
          <FilePreview
            onShow={() => setEditorModalVisibleState(true, "commitment")}
            title={watch("programDetails.0.commitmentName")}
            showButtonLabel={t("openAndEdit")}
            callbackValue="preview"
            loading={isCommitmentTemplateLoading}
            disabled={!watch("programDetails.0.commitmentTemplate")}
          />
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Other */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("others")} elementId={4}>
          <FileInput label={t("Common:attachments")} onChange={() => {}} clickHereForLabel={t("Common:toAttachFile")} />
          <div className="d-flex gap-2">
            <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "dummy.png")} />
            <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "dummy.png")} />
          </div>

          <div className="divider" />

          <FileInput label={t("adImage")} onChange={() => {}} clickHereForLabel={t("toAttachAdImage")} />
          <div className="d-flex">
            <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "dummy.png")} />
          </div>

          <div className="divider" />

          <LookupDropdown
            wrapperClassName="col-md-3 col-sm-6 w-100"
            label={t("contractForm")}
            service="programService"
            endpoint="getAllPdfTemplates"
            query={{ search: "" /* TODO: search */ }}
            formProps={register("contractId")}
            textValueKey="pdfTemplateDetail.displayName"
            idValueKey="id"
            isPaginated
          />
        </AccordionItem>
      </Accordion>

      <div className="divider mx-4" />

      {/* Financing Calculation Terms */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("financingCalculationTerms")} elementId={5}>
          {watch("financingCalculationItems").map((_, index) => (
            <div className="d-flex gap-4 align-items-end mb-3">
              <div className="row flex-grow-1">
                <Dropdown
                  label={t("fundingType")}
                  wrapperClassName="col-4"
                  data={fundingTypeLookups}
                  onChange={(value, item) => {
                    setValue(`financingCalculationItems.${index}.financingTypeId`, +value);
                    setValue(`financingCalculationItems.${index}.calculationWay`, item?.calculationWay);
                    setValue(`financingCalculationItems.${index}.notes`, "");
                  }}
                  value={watch(`financingCalculationItems.${index}.financingTypeId`)}
                />
                {/* TODO: We have a magic number here (1,2) financingTypeId - is this value final? */}
                <FormInput
                  wrapperClassName="col-4"
                  label={
                    !!watch(`financingCalculationItems.${index}.calculationWay`)
                      ? t("calculationMethod")
                      : t("Common:notes")
                  }
                  {...(!watch(`financingCalculationItems.${index}.calculationWay`)
                    ? register(`financingCalculationItems.${index}.notes`)
                    : { value: watch(`financingCalculationItems.${index}.calculationWay`) })}
                />
                <RangeInput
                  wrapperClassName="col-4"
                  label={t("fundingAmountFromTo")}
                  leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
                  placeholderStart={t("Common:from")}
                  placeholderEnd={t("Common:to")}
                  value={[
                    watch(`financingCalculationItems.${index}.fromValue`)?.toString() || "",
                    watch(`financingCalculationItems.${index}.toValue`)?.toString() || "",
                  ]}
                  onChange={(value) => {
                    setValue(`financingCalculationItems.${index}.fromValue`, +value[0]);
                    setValue(`financingCalculationItems.${index}.toValue`, +value[1]);
                  }}
                />
              </div>
              <IconButton
                size="xl"
                variant="danger"
                icon="icon-delete"
                onClick={() => onFinancingCalculationItemDelete(index)}
              />
            </div>
          ))}

          <FinancingCalculationItemTemplate onAdd={onFinancingCalculationItemAdd} />
        </AccordionItem>
      </Accordion>

      {/* Description */}
      <Accordion>
        <AccordionItem initiallyOpen title={t("Common:description")} elementId={6}>
          {/* @ts-ignore - src is typed as string which is incorrect based on the docs */}
          {/* <Form src={sampleFormIoDefinition} /> */}
          {/* TODO: Form builder is broken - check it */}
          {/* <FormBuilder /> */}
          Form-io builder
        </AccordionItem>
      </Accordion>

      {/* MODALS */}
      {editorModalProps.isOpen && !!editorModalProps.id && (
        <ProgramTemplateEditorModal
          isOpen
          fieldName={editorModalProps.target === "rule" ? "ruleAndConditionTemplate" : "commitmentTemplate"}
          content={getValues().programDetails}
          onClose={() => {
            console.log("closed");
            setEditorModalVisibleState(false);
          }}
          onSave={(content) => onEditorContentSave(content, "rule")}
        />
      )}
    </form>
  );
};

export default AddProgramView;
