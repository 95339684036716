import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const SubProceduresTab = () => {
  const { t } = useTranslation(["Orders", "Common"]);

  const data = [
    {
      actionName: "اسم الإجراء",
      actionNumber: "رقم الإجراء",
      falio: "فاليو",
      currentPhase: "المرحلة الحالية",
      actionCreatorName: "المنشئ",
      creationDate: new Date(),
      status: "Completed",
    },
    {
      actionName: "اسم الإجراء",
      actionNumber: "رقم الإجراء",
      falio: "فاليو",
      currentPhase: "المرحلة الحالية",
      actionCreatorName: "المنشئ",
      creationDate: new Date(),
      status: "Completed",
    },
    {
      actionName: "اسم الإجراء",
      actionNumber: "رقم الإجراء",
      falio: "فاليو",
      currentPhase: "المرحلة الحالية",
      actionCreatorName: "المنشئ",
      creationDate: new Date(),
      status: "pending",
    },
  ];

  const proceduresTableHeaders = useMemo<GridSchema[]>(() => {
    return [
      {
        accessor: "actionName",
        field: "actionName",
        displayName: t("actionName"),
      },
      {
        accessor: "actionNumber",
        field: "actionNumber",
        displayName: t("actionNumber"),
      },
      {
        accessor: "falio", //TODO: what is falio?
        field: "falio",
        displayName: t("falio"),
      },
      {
        accessor: "currentPhase",
        field: "currentPhase",
        displayName: t("currentPhase"),
      },
      {
        accessor: "actionCreatorName",
        field: "actionCreatorName",
        displayName: t("actionCreatorName"),
      },
      {
        accessor: "creationDate",
        field: "creationDate",
        displayName: t("creationDate"),
        render: (row) => <>{new Date(row.creationDate).toLocaleDateString()}</>, // TODO: ask about the date format
      },
      {
        accessor: "status",
        field: "status",
        displayName: t("status"),
        render(row) {
          // TODO: color based on status (primary, success, danger)
          const statusClassName = row.status === "Completed" ? "bg-success" : "bg-primary";
          return <span className={`badge rounded-4 ${statusClassName} py-2`}>{t(`Common:${row.status}`)}</span>;
        },
      },
    ];
  }, [t]);

  return (
    <div>
      <h4>{t("subProcedures")}</h4>
      <Grid gridSchema={proceduresTableHeaders} data={data} />
    </div>
  );
};

export default SubProceduresTab;
