import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { OrderLocalResult } from "services/orders/models/OrdersTypes";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { OrdersService } from "services/orders";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";

export default function OrderGridView() {
  const { t } = useTranslation("Orders");
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const [orders, setOrders] = useState<OrderLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);

  const { sortState, pagination, setPagination, searchValue } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
    })),
  );

  const fetchOrders = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await OrdersService.getAllOrdersAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });

      setOrders(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pagination.currentPage === 1) return;
    fetchOrders();
  }, [pagination.currentPage]);

  useEffect(() => {
    fetchOrders({ resetPagination: true });
  }, [sortState, searchValue /* TODO: model filter */]);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "id",
        field: "id",
        displayName: t("orderNumber"),
        sortable: true,
        render: (row: OrderLocalResult) => {
          return <Link to={`view/${row.id}`}>{row.serialNumber}</Link>;
        },
      },
      {
        field: "orderDetail.programName",
        displayName: t("programName"),
        sortable: true,
        filterable: true,
        render(row: OrderLocalResult) {
          return <>{row.programDetail.displayName}</>;
        },
      },
      {
        accessor: "appliedUserName",
        field: "appliedUserName",
        displayName: t("applicantName"),
        sortable: true,
        filterable: true,
      },
      {
        accessor: "researcherClassification",
        field: "researcherClassification",
        displayName: t("researcherClassification"),
        sortable: true,
      },
      {
        field: "createdOn",
        displayName: t("submissionDate"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render(row: OrderLocalResult) {
          return <>{new Date(row.createdOn).toLocaleDateString()}</>;
        },
      },
      {
        field: "centreManagementDetail.displayName",
        displayName: t("researchesCenter"),
        sortable: true,
        filterable: true,
        render(row: OrderLocalResult) {
          return <>{row.centreManagementDetail.displayName}</>;
        },
      },
      {
        field: "orderPhase",
        displayName: t("orderStage"),
        sortable: true,
        filterable: true,
      },
      {
        field: "centreManagerName",
        displayName: t("responsibleParty"),
        sortable: true,
        filterable: true,
      },
      {
        field: "orderStatus",
        displayName: t("orderStatus"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "1", value: t("active") },
            { id: "0", value: t("inactive") },
          ],
        },
        render(row: OrderLocalResult) {
          // TODO: color based on status (primary, success, danger)
          const statusClassName = row.orderStatus === "Completed" ? "bg-success" : "bg-primary";
          return <span className={`badge rounded-4 ${statusClassName} py-2`}>{t(row.orderStatus)}</span>;
        },
      },
    ],
    [],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("allOrders"),
    singularName: t("order"),
    addPageRoute: "/home/orders/add",
    totalCount: totalCount,
    hideAddButton: true,
  };

  return (
    <div className="bg-white p-4 rounded-4 h-100">
      {/* <button onClick={() => fetchOrders()}>Fetch orders</button> */}
      <GridView
        loading={loading}
        gridProps={{
          data: orders ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
    </div>
  );
}
