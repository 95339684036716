import { BaseResponseType } from "services/shared-models/sharedTypes";
import { HttpClient } from "helpers";
import {
  CountryCodeResultResponse,
  CountryResultResponse,
  DegreeResultResponse,
  GenderResultResponse,
  JobResultResponse,
  AllProgramTypesResultResponse,
  ProgramClassificationResultResponse,
  ProgramCycleYearsResultResponse,
  AudienceTypesResultResponse,
  FinancingTypesResultResponse,
} from "./models/LookupsType";
import qs from "qs";

export const LookupService = {
  getCountryCodes: async () => {
    return HttpClient.get<BaseResponseType<CountryCodeResultResponse[]>>("/api/lookup/GetAllCountryCodes");
  },
  getGenders: async () => {
    return HttpClient.get<BaseResponseType<GenderResultResponse[]>>("/api/lookup/GetAllGenders");
  },
  getDegrees: async () => {
    return HttpClient.get<BaseResponseType<DegreeResultResponse[]>>("/api/lookup/GetAllDegrees");
  },
  getJobs: async () => {
    return HttpClient.get<BaseResponseType<JobResultResponse[]>>("/api/lookup/GetAllJobs");
  },
  getNationalities: async () => {
    return HttpClient.get<BaseResponseType<CountryResultResponse[]>>("/api/lookup/GetAllCountries");
  },
  getAllProgramTypes: async () => {
    return HttpClient.get<BaseResponseType<AllProgramTypesResultResponse[]>>("/api/lookup/GetAllProgramTypes");
  },
  getAllProgramClassifications: async () => {
    return HttpClient.get<BaseResponseType<ProgramClassificationResultResponse[]>>(
      "/api/lookup/GetAllProgramClassifications",
    );
  },
  getAllProgramClassificationsByProgramTypeId: async (query: { programTypeId: number }) => {
    return HttpClient.get<BaseResponseType<ProgramClassificationResultResponse[]>>(
      `/api/lookup/GetAllProgramClassificationsByProgramTypeId?${qs.stringify(query)}`,
    );
  },
  getAllProgramCycleYears: async () => {
    return HttpClient.get<BaseResponseType<ProgramCycleYearsResultResponse[]>>("/api/lookup/GetAllProgramCycleYears");
  },
  getAllProgramCycleByYear: async (query: { year: number }) => {
    return HttpClient.get<BaseResponseType<ProgramCycleYearsResultResponse[]>>(
      `/api/lookup/GetAllProgramCycleByYear?${qs.stringify(query)}`,
    );
  },
  getAllAudienceTypes: async () => {
    return HttpClient.get<BaseResponseType<AudienceTypesResultResponse[]>>("/api/lookup/GetAllAudienceTypes");
  },
  getAllFinancingTypes: async () => {
    return HttpClient.get<BaseResponseType<FinancingTypesResultResponse[]>>("/api/lookup/GetAllFinancingTypes");
  },
};
