import moment from "moment-hijri";

const toGregorian = (hijriDate: string | Date, onlyDate = false): string => {
  const format = onlyDate ? "D/M/YYYY" : "D/M/YYYY h:mm:ss a";
  return moment(new Date(hijriDate)).format(format);
};

const toHijri = (gregorianDate: string | Date, onlyDate = false): string => {
  const format = onlyDate ? "iD/iM/iYYYY" : "iD/iM/iYYYY h:mm:ss a";
  return moment(new Date(gregorianDate)).format(format);
};

const toLocalizedDate = (date: string | Date, onlyDate = false, locale = "ar"): string => {
  return locale === "ar" ? toHijri(date, onlyDate) : toGregorian(date, onlyDate);
};

export { toGregorian, toHijri, toLocalizedDate };
