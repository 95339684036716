import { z } from "zod";

//  TODO: error message localization
export const createProgramSchema = z.object({
  status: z.string(),
  programTypeId: z.number(), // state placeholder (not sent to backend)
  cycleYear: z.number(), // state placeholder (not sent to backend)

  programDetails: z.array(
    z.object({
      displayName: z.string(),
      description: z.string(),
      ruleAndConditionName: z.string(),
      ruleAndConditionTemplate: z.string(),
      commitmentName: z.string(),
      commitmentTemplate: z.string(),
      locale: z.enum(["En", "Ar"]),
    }),
  ),
  classificationProgramId: z.number().min(1),
  contractStartDate: z.date(),
  fundingDurationInMonths: z.number().min(1),
  adsStartDate: z.date(),
  adsEndDate: z.date(),
  fundingAmount: z.number().min(0),
  audienceTypesIds: z.array(z.number()),
  programCycleId: z.number(),
  programAttachmentsIds: z.array(z.string()),
  adsImageId: z.string(),
  contractId: z.number(),
  financingCalculationItems: z.array(
    z.object({
      financingTypeId: z.number().min(1),
      calculationWay: z.string(), // state placeholder (not sent to backend)
      notes: z.string().nullish(),
      fromValue: z.number(),
      toValue: z.number(),
    }),
  ),
  formBuilderSchema: z.string(),
});

export type CreateProgramRequest = z.infer<typeof createProgramSchema>;
