import Modal from "components/blocks/Modal";
import RichTextEditor from "components/RichTextEditor";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateProgramRequest } from "../program.schema";

type ProgramTemplateEditorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (content: { ar: string; en: string }) => void;
  content: CreateProgramRequest["programDetails"];
  fieldName: "ruleAndConditionTemplate" | "commitmentTemplate";
};

const ProgramTemplateEditorModal: React.FC<ProgramTemplateEditorModalProps> = ({
  isOpen,
  onClose,
  onSave,
  content,
  fieldName,
}) => {
  const { t } = useTranslation("Programs");
  const [editorContent, setEditorContent] = useState({
    ar: content.find((x) => x.locale === "Ar")?.[fieldName] ?? "",
    en: content.find((x) => x.locale === "En")?.[fieldName] ?? "",
  });

  const onArEditorContentChange = (text: string) => {
    setEditorContent((prev) => ({ ...prev, ar: text }));
  };

  const onEnEditorContentChange = (text: string) => {
    setEditorContent((prev) => ({ ...prev, en: text }));
  };

  const onSaveClicked = () => {
    onSave(editorContent);
    onClose();
  };

  return (
    <Modal
      fullscreen
      isOpen={isOpen}
      onClose={onClose}
      title={t("editForm")}
      footer={
        <div className="d-flex justify-content-end gap-3">
          <button type="button" className="btn btn-secondary" onClick={onClose}>
            {t("Common:cancel")}
          </button>
          <button type="button" className="btn btn-primary" onClick={onSaveClicked}>
            {t("Common:save")}
          </button>
        </div>
      }
    >
      <div className="row">
        <div className="col-6">
          <h5>عربي</h5>
          <RichTextEditor initialData={editorContent.ar} onChange={onArEditorContentChange} />
        </div>
        <div className="col-6">
          <h5>English</h5>
          <RichTextEditor initialData={editorContent.en} onChange={onEnEditorContentChange} />
        </div>
      </div>
    </Modal>
  );
};

export default ProgramTemplateEditorModal;
