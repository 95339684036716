import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import Dropdown from "components/blocks/Dropdown";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormInput from "components/blocks/FormInput";
import { Ref, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import SpeechesTable from "./SpeechesTable";

const SpeechesTab = forwardRef((props, ref: Ref<HTMLFormElement>) => {
  const { t } = useTranslation(["Orders", "Common"]);
  /*  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<any>({
    values: {
      SpeechNumber: "",
      date: new Date(),
      members: "",
      attachments: [],
    },
    //resolver: getSetPasswordSchemaSchema(t),
  }); */
  return (
    <form ref={ref}>
      <Accordion>
        <AccordionItem initiallyOpen title={t("newSpeech")} elementId={"new-speech"}>
          <div className="d-flex flex-column gap-2 pb-4 border-bottom border-1">
            <div className="d-flex flex-wrap gap-2 align-items-start">
              <Dropdown
                data={[
                  { id: "option 1", value: "option 1" },
                  { id: "option 2", value: "option 2" },
                  { id: "option 3", value: "option 3" },
                ]}
                label={t("speechesTemplates")}
                wrapperClassName="flex-1"
              />

              <FormInput label={t("description")} wrapperClassName="flex-2" />
              <Dropdown
                data={[
                  { id: "option 1", value: "option 1" },
                  { id: "option 2", value: "option 2" },
                  { id: "option 3", value: "option 3" },
                ]}
                label={t("templateLanguage")}
              />
              <button className="btn btn-primary rounded align-self-end p-10px">{t("release")}</button>
            </div>
            <div className="d-flex ">
              <FilePreview onDelete={() => {}} onShow={() => {}} file={new File([""], "dummy.png")} />
            </div>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion>
        <AccordionItem initiallyOpen title={t("speeches")} elementId={"previous-speeches"}>
          <SpeechesTable />
        </AccordionItem>
      </Accordion>
    </form>
  );
});

export default SpeechesTab;
