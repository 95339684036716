import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import IconButton from "components/blocks/IconButton";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Update = {
  details: string;
  completionRate: string;
  evaluation: string;
  date: string;
};

const Updates = () => {
  const { t } = useTranslation("Orders");

  const updates: Update[] = [
    {
      details: "Details",
      completionRate: "80%",
      evaluation: "مرضي",
      date: new Date().toLocaleDateString(),
    },
    {
      details: "Details",
      completionRate: "20%",
      evaluation: "مرضي",
      date: new Date().toLocaleDateString(),
    },
    {
      details: "Details",
      completionRate: "30%",
      evaluation: "غير مرضي",
      date: new Date().toLocaleDateString(),
    },
  ];

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "details",
        displayName: t("details"),
        field: "details",
        columnClassName: "w-50",
      },
      {
        field: "completionRate",
        accessor: "completionRate",
        displayName: t("completionRate"),
      },
      {
        field: "evaluation",
        accessor: "evaluation",
        displayName: t("evaluation"),
      },
      {
        field: "date",
        accessor: "date",
        displayName: t("date"),
        render(row: Update) {
          return <>{new Date(row.date).toLocaleDateString()}</>;
        },
      },
      {
        field: "actions",
        accessor: "actions",
        displayName: t("actions"),
        render: (row: Update) => (
          <div className="d-flex  gap-2">
            <IconButton icon="icon-document" variant="transparent" innerIconColor="primary" size="lg" />
            <IconButton icon="icon-document" variant="transparent" innerIconColor="dark" size="lg" />
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("updates")} elementId={2}>
        <Grid gridSchema={gridSchema} data={updates} />
      </AccordionItem>
    </Accordion>
  );
};

export default Updates;
