import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const SpeechesTable = () => {
  const { t } = useTranslation("Orders");

  const data = [
    {
      speechNumber: "رقم السجل",
      date: new Date(),
      speechName: "اسم السجل",
      speechDescription: "وصف السجل",
      createdBy: "المنشئ",
      speech: "السجل",
    },
    {
      speechNumber: "رقم السجل",
      date: new Date(),
      speechName: "اسم السجل",
      speechDescription: "وصف السجل",
      createdBy: "المنشئ",
      speech: "السجل",
    },
    {
      speechNumber: "رقم السجل",
      date: new Date(),
      speechName: "اسم السجل",
      speechDescription: "وصف السجل",
      createdBy: "المنشئ",
      speech: "السجل",
    },
  ];

  const speechTableHeaders = useMemo<GridSchema[]>(() => {
    return [
      {
        accessor: "speechNumber",
        field: "speechNumber",
        displayName: t("speechNumber"),
      },
      {
        accessor: "speechName",
        field: "speechName",
        displayName: t("speechName"),
      },
      {
        accessor: "speechDescription",
        field: "speechDescription",
        displayName: t("speechDescription"),
      },
      {
        accessor: "createdBy",
        field: "createdBy",
        displayName: t("createdBy"),
      },
      {
        accessor: "date",
        field: "date",
        displayName: t("date"),
        render: (row) => <>{new Date(row.date).toLocaleDateString()}</>, // TODO: ask about the date format
      },
      {
        accessor: "speech",
        field: "speech",
        displayName: t("speech"),
      },
      {
        accessor: "actions",
        field: "actions",
        displayName: t("actions"),
        render(row) {
          // TODO: color based on status (primary, success, danger)
          const statusClassName = row.status === "Completed" ? "bg-success" : "bg-primary";
          return <span className={`badge rounded-4 ${statusClassName} py-2`}>{t(row.status)}</span>;
        },
      },
    ];
  }, [t]);

  return <Grid gridSchema={speechTableHeaders} data={data} />;
};

export default SpeechesTable;
