import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import FileInput from "components/blocks/FileInput";
import FormInput from "components/blocks/FormInput";
import { Ref, forwardRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AdditionalAttachmentsTab = forwardRef((props, ref: Ref<HTMLFormElement>) => {
  const { t } = useTranslation(["Orders", "Common"]);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<any>({
    values: {
      description: "",
      attachments: [],
    },
    //resolver: getSetPasswordSchemaSchema(t),
  });

  const attachments = [
    {
      id: 1,
      fileName: "اسم الملف",
      size: "1MB",
      extension: "pdf",
    },
    {
      id: 2,
      fileName: "file2",
      size: "2MB",
      extension: "doc",
    },
    {
      id: 3,
      fileName: "اسم الملف",
      size: "1MB",
      extension: "pdf",
    },
    {
      id: 4,
      fileName: "اسم الملف",
      size: "1MB",
      extension: "pdf",
    },
    {
      id: 5,
      fileName: "اسم الملف",
      size: "2MB",
      extension: "doc",
    },
    {
      id: 6,
      fileName: "اسم الملف",
      size: "1MB",
      extension: "pdf",
    },
    {
      id: 7,
      fileName: "اسم الملف",
      size: "1MB",
      extension: "pdf",
    },
  ];

  const onAddAttachment = (data: any) => {};

  return (
    <form ref={ref}>
      <Accordion>
        <AccordionItem initiallyOpen title={t("newAttachment")} elementId={"new-attachment"}>
          <FileInput label={t("attachments")} onChange={(files) => {}} clickHereForLabel={t("Common:toAttachFile")} />

          <div>
            <label>{t("description")}</label>
            <div className="d-flex flex-wrap gap-2">
              <FormInput
                wrapperClassName="flex-grow-1"
                placeholder={t("description")}
                error={errors.description?.message as string}
                {...register("description")}
              />
              <button className="btn btn-primary " onClick={onAddAttachment}>
                {t("add")}
              </button>
            </div>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion>
        <AccordionItem initiallyOpen title={t("attachments")} elementId={"attachments"}>
          <div className="d-flex gap-2 flex-wrap">
            {attachments.map((attachment) => (
              <AttachmentCard key={attachment.id} attachment={attachment} />
            ))}
          </div>
        </AccordionItem>
      </Accordion>
    </form>
  );
});

export default AdditionalAttachmentsTab;
