import { RouteItemConfig } from "../models";
import DashboardView from "../../views/home/dashboard/dashboardView";
import ProgramsGridView from "views/programs/ProgramsGridView";
import AddProgramView from "views/programs/Forms/AddProgramView";
import OrderGridView from "views/orders/OrderGridView";
import OrderView from "views/orders/OrderView";

export const HomeRoutes: RouteItemConfig[] = [
  {
    path: "/home/dashboard",
    name: "dashboard",
    component: DashboardView,
    icon: "icon-dashboard",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/programs",
    name: "allPrograms",
    component: ProgramsGridView,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/programs/add",
    name: "",
    component: AddProgramView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/orders",
    name: "allOrders",
    component: OrderGridView,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/orders/view/:orderId",
    name: "allOrders",
    component: OrderView,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/tasks",
    name: "tasks",
    component: DashboardView,
    icon: "icon-tasks",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/paymentsAndRewards",
    name: "paymentsAndRewards",
    component: DashboardView,
    icon: "icon-dashboard",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/paymentsAndRewards/reviewerRewards",
        name: "reviewerRewards",
        component: DashboardView,
        icon: "icon-dashboard",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/paymentsAndRewards/examinerRewards",
        name: "examinerRewards",
        component: DashboardView,
        icon: "icon-dashboard",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/paymentsAndRewards/researcherPayments",
        name: "researcherPayments",
        component: DashboardView,
        icon: "icon-dashboard",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
];
