import React from "react";
import "./RangeInput.scss";

type RangeInputProps = {
  leftElement?: React.ReactNode;
  label?: string;
  wrapperClassName?: string;
  placeholderStart?: string;
  placeholderEnd?: string;
  value: [string, string];
  onChange: (value: [string, string]) => void;
};

const RangeInput: React.FC<RangeInputProps> = (props) => {
  const { leftElement, label, wrapperClassName, placeholderEnd, placeholderStart, value, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = [...value];
    newValue[index] = e.target.value;
    onChange(newValue as [string, string]);
  };

  return (
    <div className={wrapperClassName}>
      {label && <label className="mb-1">{label}</label>}
      <div className="input-range form-control d-flex align-items-center gap-2">
        <input
          className="form-control clear-borders flex-grow-1"
          style={{ minWidth: 0 }}
          placeholder={placeholderStart}
          value={value[0]}
          onChange={(e) => handleChange(e, 0)}
        />
        <span className="fw-bold">-</span>
        <input
          className="form-control clear-borders flex-grow-1"
          style={{ minWidth: 0 }}
          placeholder={placeholderEnd}
          value={value[1]}
          onChange={(e) => handleChange(e, 1)}
        />
        {Boolean(leftElement) && <span className="px-2">{leftElement}</span>}
      </div>
    </div>
  );
};

export default RangeInput;
