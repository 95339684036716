import { HttpClient } from "helpers";
import { BaseResponseType, DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import {
  ConditionsAndRulesByIDResponse,
  ConditionsAndRulesResultResponse,
  PDFTemplateByIDResponse,
  PDFTemplatesResultResponse,
  ProgramLocalResult,
} from "./models/ProgramTypes";

export const ProgramsService = {
  getAllProgramsAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<ProgramLocalResult>>("/api/Programs/GetAllPrograms", data);
  },
  getAllConditionsAndRules: async (request: { search: string; pageIndex: number; pageSize: number }) => {
    return HttpClient.post<PagedResponseType<ConditionsAndRulesResultResponse[]>>(
      "/api/Programs/GetAllConditionsAndRules",
      request,
    );
  },
  getAllPdfTemplates: async (request: { search: string; pageIndex: number; pageSize: number }) => {
    return HttpClient.post<PagedResponseType<PDFTemplatesResultResponse>>("/api/Programs/GetAllPdfTemplates", request);
  },
  getConditionAndRuleById: async (id: number) => {
    return HttpClient.get<BaseResponseType<ConditionsAndRulesByIDResponse>>(
      `/api/Programs/GetConditionAndRuleById/${id}`,
    );
  },
  getPdfTemplateById: async (id: number) => {
    return HttpClient.get<BaseResponseType<PDFTemplateByIDResponse>>(`/api/Programs/GetPdfTemplateById/${id}`);
  },
};
