import { ReactNode, useState } from "react";
import HeadersSection from "./HeadersSection";

export type TabProps = {
  id: string;
  title: string;
  content: ReactNode;
};

export type PageHeaderProps = {
  title: string;
  withOutCircleIcon?: boolean;
  value: ReactNode;
};

export type PageWithTabsProps = {
  title: string;
  titleImgSrc?: string;
  subTitle?: string | ReactNode | ReactNode[];
  containerClassName?: string;
  withBackButton?: boolean;
  actions?: (selectedTabId: string) => ReactNode;
  pageHeaders?: PageHeaderProps[];
  tabs: TabProps[];
  defaultTabId: string;
  tapContentContainerClassName?: string;
  keepTabsMounted?: boolean;
};

const PageWithTabs: React.FC<PageWithTabsProps> = (props) => {
  const [activeTabId, setActiveTabId] = useState(props.defaultTabId);

  return (
    <div className="bg-body ps-2" style={{ margin: -20 }}>
      {/* TODO: check props name 'containerClassName' */}
      <div
        className={`${
          props.containerClassName ? props.containerClassName : " d-flex flex-column gap-4 bg-white rounded-4 p-4"
        }`}
      >
        <HeadersSection {...props} activeTabId={activeTabId} />
        <div className="d-flex gap-2">
          {props.tabs.map((tab) => (
            <button
              key={tab.id}
              className={`fs-14px px-3 py-2 rounded-4 btn btn-outline-primary  ${
                tab.id === activeTabId ? " active fw-bold" : "border-light-300  text-dark"
              }`} /* TODO: fix text color for unselected item */
              onClick={() => setActiveTabId(tab.id)}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>

      <div
        className={`${
          props.tapContentContainerClassName ? props.tapContentContainerClassName : " mt-3 p-4 bg-white rounded-4"
        }`}
      >
        {props.keepTabsMounted
          ? props.tabs.map((tab) => (
              <div key={tab.id} className={tab.id === activeTabId ? "" : "d-none"}>
                {tab.content}
              </div>
            ))
          : props.tabs.find((tab) => tab.id === activeTabId)?.content}
      </div>
    </div>
  );
};

export default PageWithTabs;
