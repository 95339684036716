import { useShallow } from "zustand/react/shallow";
import useGridStore from "../grid.store";
import { GridProps } from "../GridView.types";
import { SortDirection } from "services/shared-models/sharedEnums";
import { useTranslation } from "react-i18next";

const Grid: React.FC<GridProps> = (props) => {
  const { t } = useTranslation("Common");
  const { data, gridSchema } = props;
  const { sortState, toggleFieldSortState } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      toggleFieldSortState: state.toggleFieldSortState,
    })),
  );

  const handleToggleSortClick = (field: string) => {
    const dir = sortState?.[field];
    if (!dir) return toggleFieldSortState(field, SortDirection.Asc);

    const newDir = dir === SortDirection.Asc ? SortDirection.Desc : null;
    toggleFieldSortState(field, newDir);
  };

  return (
    <div className="table-responsive">
      <table className={`table table-rounded ${props.gridFooter ? "has-footer" : ""}`}>
        <thead>
          <tr>
            {gridSchema.map((header) =>
              header.sortable ? (
                <th
                  key={header.field}
                  className={`user-select-none sortable ${SortDirection[sortState?.[header.field] ?? 0]} ${
                    header?.columnClassName ?? ""
                  }`}
                  onClick={() => handleToggleSortClick(header.field)}
                >
                  {header.displayName}
                </th>
              ) : (
                <th className="user-select-none" key={header.field}>
                  {header.displayName}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr>
              <td colSpan={gridSchema.length} className="text-center">
                {t("noData")}
              </td>
            </tr>
          )}
          {data.map((row, idx) => (
            <tr key={idx}>
              {gridSchema.map((header) => (
                <td key={header.field} style={{ verticalAlign: "middle" }}>
                  {header.render ? header.render(row) : row[header.accessor ?? header.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>

        {props.gridFooter && (
          <tfoot>
            {props.gridFooter.map((footerRow, rowIndex) => (
              <tr key={rowIndex}>
                {footerRow.map((footerCell, cellIndex) => (
                  <td key={cellIndex} colSpan={footerCell.colSpan ?? 1} className={footerCell.cellClassName}>
                    {footerCell.content}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default Grid;
