import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import DatePicker from "components/blocks/DatePicker";
import FilePreview from "components/blocks/FileInput/FilePreview";
import FormInput from "components/blocks/FormInput";
import { Ref, forwardRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PreviousRecords from "./PreviousRecords";
import FileInput from "components/blocks/FileInput";

const MeetingsRecordsTab = forwardRef((props, ref: Ref<HTMLFormElement>) => {
  const { t } = useTranslation(["Orders", "Common"]);
  /*  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<any>({
    values: {
      recordNumber: "",
      date: new Date(),
      members: "",
      attachments: [],
    },
    //resolver: getSetPasswordSchemaSchema(t),
  }); */
  return (
    <form ref={ref}>
      <Accordion>
        <AccordionItem initiallyOpen title={t("newRecord")} elementId={"new-record"}>
          <div className="d-flex flex-column gap-2 pb-4 border-bottom border-1">
            <div className="d-flex gap-2 ">
              <FormInput label={t("recordNumber")} wrapperClassName="flex-grow-1" /* {...register("recordNumber")} */ />

              <DatePicker label={t("date")} onChange={(files) => {}} wrapperClassName="flex-grow-1" />

              <FormInput label={t("members")} onChange={(files) => {}} wrapperClassName="flex-grow-1" />
            </div>
            <div className="d-flex">
              <FormInput label={t("recordDetails")} onChange={(files) => {}} wrapperClassName="flex-grow-1" />
            </div>

            <div className="d-flex flex-column">
              <div className="d-flex flex-grow-1">
                <FileInput
                  label={t("attachments")}
                  onChange={(files) => {}}
                  clickHereForLabel={t("Common:toAttachFile")}
                  containerWrapperClassName="flex-grow-1"
                />
              </div>
              <div className="d-flex flex-wrap gap-2 mt-2">
                {[1, 2, 3, 4, 5, 6, 7].map((file) => (
                  <FilePreview
                    wrapperClassName="flex-grow-1"
                    onDelete={() => {}}
                    onShow={() => {}}
                    file={new File([""], "dummy.png")}
                  />
                ))}
              </div>
            </div>
          </div>
        </AccordionItem>
      </Accordion>

      <Accordion>
        <AccordionItem initiallyOpen title={t("previousRecords")} elementId={"previous-records"}>
          <PreviousRecords />
        </AccordionItem>
      </Accordion>
    </form>
  );
});

export default MeetingsRecordsTab;
