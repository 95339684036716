import { HttpClient } from "helpers";
import { BaseResponseType, DataGridRequest } from "services/shared-models/sharedTypes";
import {
  CreateOrderRequest,
  GetAllOrdersResponse,
  OrderLocalItemResult,
  OrderLocalResult,
  OrderResult,
} from "./models/OrdersTypes";

export const OrdersService = {
  getAllOrdersAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<GetAllOrdersResponse>("/api/Orders/GetAllOrders", data);
  },
  getOneById: async (id: number) => {
    return await HttpClient.get<BaseResponseType<OrderLocalItemResult>>(`/api/Orders/GetOrderById/${id}`);
  },
  createOrder: async (data: CreateOrderRequest) => {
    return await HttpClient.post<BaseResponseType<OrderResult>>("/api/Orders", data);
  },
  exportToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<string>>("/api/Orders/ExportToExcel", data); // TODO: download file and fix the response type
  },
};
