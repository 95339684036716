import "./LoadingOverlay.scss";

type LoadingOverlayProps = {
  visible?: boolean;
  className?: string;
};

export default function LoadingOverlay({ visible = true, className }: LoadingOverlayProps) {
  if (!visible) return null;

  return (
    <div className={`loading-overlay rounded-3 ${className}`}>
      <div className="spinner-border text-primary">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
