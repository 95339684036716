import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type FundingTerm = {
  agreementTerms: string;
  name: string;
  duration: string;
  amount: number;
  totalAmount: number;
};

const FundingTerms = () => {
  const { t } = useTranslation("Orders");

  const fundingTerms: FundingTerm[] = [
    {
      agreementTerms: "مشارك رئيسي",
      name: "علي خالد سليم",
      duration: "1 شهر",
      amount: 100,
      totalAmount: 500,
    },
    {
      agreementTerms: "مشارك رئيسي",
      name: "علي خالد سليم",
      duration: "12 شهر",
      amount: 100,
      totalAmount: 3500,
    },
    {
      agreementTerms: "معدات ",
      name: "علي خالد سليم",
      duration: "10 شهر",
      amount: 100,
      totalAmount: 2000,
    },
  ];

  const fundingTermsTableHeaders = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "agreementTerms",
        field: "agreementTerms",
        displayName: t("agreementTerms"),
        render: (row: FundingTerm) => {
          //TODO: this should be a link for some values
          return row.agreementTerms === "مشارك رئيسي" ? (
            <Link to="#">{row.agreementTerms}</Link>
          ) : (
            <>{row.agreementTerms}</>
          );
        },
      },
      {
        accessor: "name",
        field: "name",
        displayName: t("name"),
      },
      {
        accessor: "duration",
        field: "duration",
        displayName: t("counts"),
      },
      {
        accessor: "amount",
        field: "amount",
        displayName: t("amount", { unit: t("ryal") }),
      },
      {
        accessor: "totalAmount",
        field: "totalAmount",
        displayName: t("totalAmount", { unit: t("ryal") }),
      },
    ],
    [],
  );

  const tableFooter = useMemo(() => {
    return [
      [
        {
          content: "",
          colSpan: 3,
        },
        {
          content: (
            <div>
              <span className="p-2 d-flex justify-content-end fw-bold">
                قيمة الصرف :{fundingTerms.reduce((acc, curr) => acc + curr.amount, 0)}
              </span>
            </div>
          ),
          colSpan: 1,
        },
        {
          content: (
            <div>
              <span className="p-2 d-flex  fw-bold">
                المجموع الكلي : {fundingTerms.reduce((acc, curr) => acc + curr.totalAmount, 0)} ريال
              </span>
            </div>
          ),
          colSpan: 1,
        },
      ],
    ];
  }, []);

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("fundingTerms")} elementId={4}>
        <div className="pb-2 border-bottom border-1">
          <Grid gridSchema={fundingTermsTableHeaders} data={fundingTerms} gridFooter={tableFooter} />
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default FundingTerms;
