import IconButton from "components/blocks/IconButton";
import { PageWithTabsProps } from ".";
import { useNavigate } from "react-router-dom";

const HeadersSection = (props: PageWithTabsProps & { activeTabId: string }) => {
  const navigate = useNavigate();

  const onBackClicked = () => {
    navigate(-1);
  };

  return (
    <div className=" d-flex flex-column gap-3 border-bottom border-1">
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex gap-2 align-items-center">
          {props.titleImgSrc ? (
            <img src={props.titleImgSrc} alt={props.title} width={40} height={40} />
          ) : (
            <IconButton
              icon="icon-full-arrow-right"
              size="lg"
              fitToIconWidth
              onClick={onBackClicked}
              innerIconColor="secondary"
              iconPosition="start"
            />
          )}

          <div>
            <div className="fs-5 fw-bold">{props.title}</div>
            <div className="fs-6 text-gray">{props.subTitle}</div>
          </div>
        </div>

        {/* Actions */}
        {props.actions && <div className="d-flex flex-wrap gap-2">{props.actions(props.activeTabId)}</div>}
      </div>

      {/* Page Headers */}
      {props.pageHeaders && (
        <div className="d-flex flex-wrap gap-3  pb-4">
          {props.pageHeaders.map((header, index) => (
            <div key={index} className="d-flex align-items-center">
              {!header.withOutCircleIcon && <div className="header-circle text-gray"></div>}
              <div>
                {<div className="fs-14px text-gray">{header.title}</div>}
                <div className="fs-14px fw-bold">{header.value}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeadersSection;
