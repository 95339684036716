import PageWithTabs from "components/PageWithTabs";
import IconButton from "components/blocks/IconButton";
import { OrderInfoTab } from "./Tabs/OrderInfoTab";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import SubProceduresTab from "./Tabs/SubProceduresTab";
import AdditionalAttachmentsTab from "./Tabs/AdditionalAttachmentsTab";
import MeetingsRecordsTab from "./Tabs/MeetingsRecordsTab";
import SpeechesTab from "./Tabs/SpeechesTab";
import { useParams } from "react-router-dom";

type FormTabs = "meetingsRecordsTab" | "additionalAttachmentsTab" | "speechesTab";
type TapsIds = "orderInfoTab" | "subProceduresTab" | FormTabs;

const tabsIds: {
  [key in TapsIds]: TapsIds;
} = {
  orderInfoTab: "orderInfoTab",
  meetingsRecordsTab: "meetingsRecordsTab",
  additionalAttachmentsTab: "additionalAttachmentsTab",
  subProceduresTab: "subProceduresTab",
  speechesTab: "speechesTab",
};

const OrderView = () => {
  const { t } = useTranslation("Orders");
  const { orderId } = useParams();

  const tapsFormRefs = useRef<{
    [key in FormTabs]: React.RefObject<HTMLFormElement>;
  }>({
    additionalAttachmentsTab: useRef<HTMLFormElement>(null),
    meetingsRecordsTab: useRef<HTMLFormElement>(null),
    speechesTab: useRef<HTMLFormElement>(null),
  });

  const onSave = (selectedTabId: TapsIds) => {
    console.log(selectedTabId, tapsFormRefs.current![selectedTabId as FormTabs]);
    tapsFormRefs.current![selectedTabId as FormTabs]?.current?.click();
  };

  return (
    <PageWithTabs
      title="Order Details"
      subTitle="Order #123"
      defaultTabId={tabsIds.orderInfoTab}
      keepTabsMounted
      pageHeaders={[
        {
          title: "Order Number",
          withOutCircleIcon: true,
          value: "123",
        },
        {
          title: "Order Stage",
          value: "Stage 1",
        },
        {
          title: "Responsible Party",
          value: "Party 1",
        },
        {
          title: "Order Status",
          value: "Status 1",
        },
      ]}
      actions={(selectedTabId) => {
        return (
          <>
            {[tabsIds.meetingsRecordsTab, tabsIds.speechesTab, tabsIds.additionalAttachmentsTab].includes(
              selectedTabId as TapsIds,
            ) && (
              <button
                type="submit"
                onClick={(e) => {
                  onSave(selectedTabId as TapsIds);
                }}
                className="btn btn-primary btn-sm rounded"
              >
                {t("save")}
              </button>
            )}

            <IconButton
              icon="icon-share"
              size="md"
              className="btn action-btn btn-sm rounded p-2"
              variant="light"
              innerIconColor="secondary"
            />
            <IconButton
              icon="icon-edit"
              size="md"
              className="btn action-btn btn-sm rounded p-2"
              variant="light"
              innerIconColor="secondary"
            />
            <IconButton
              icon="icon-delete"
              size="md"
              className="btn action-btn btn-sm rounded p-2"
              variant="light"
              innerIconColor="danger"
            />
          </>
        );
      }}
      tabs={[
        {
          id: tabsIds.orderInfoTab,
          title: t("orderInfo"),
          content: <OrderInfoTab orderId={orderId ? +orderId : 0} />,
        },
        {
          id: tabsIds.meetingsRecordsTab,
          title: t("meetingsRecords"),
          content: <MeetingsRecordsTab ref={tapsFormRefs.current[tabsIds.meetingsRecordsTab as FormTabs]} />,
        },
        {
          id: tabsIds.speechesTab,
          title: t("speeches"),
          content: <SpeechesTab ref={tapsFormRefs.current[tabsIds.speechesTab as FormTabs]} />,
        },
        {
          id: tabsIds.additionalAttachmentsTab,
          title: t("additionalAttachments"),
          content: (
            <AdditionalAttachmentsTab ref={tapsFormRefs.current[tabsIds.additionalAttachmentsTab as FormTabs]} />
          ),
        },
        {
          id: tabsIds.subProceduresTab,
          title: t("subProcedures"),
          content: <SubProceduresTab />,
        },
      ]}
    />
  );
};

export default OrderView;
