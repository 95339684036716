export interface DropdownItemType extends Record<string, any> {
  value: string;
  id: string | number;
}

export type DropdownProps = {
  label: string;
  data: DropdownItemType[];
  noSelectionPlaceholder?: string;
  wrapperClassName?: string;
  value?: string | number;
  onChange?: (value: string, item: DropdownItemType) => void;
  disabled?: boolean;
  formProps?: any;
};

// TODO: Arrow icon coloring (primary)

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { label, data, noSelectionPlaceholder, wrapperClassName, value, onChange, disabled, formProps } = props;

  const onLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(e.target.value, data.find((item) => `${item.id}` === e.target.value)!);
  };

  return (
    <div className={`${wrapperClassName}`} style={{ minWidth: 150 }}>
      <label className="mb-1">{label}</label>
      <select
        onChange={onLocalChange}
        value={value}
        className="form-select form-control"
        aria-label={label}
        disabled={disabled}
        {...formProps}
      >
        <option value="" className="text-muted" disabled>
          {noSelectionPlaceholder ?? label}
        </option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
