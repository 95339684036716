import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ClassicEditor, Bold, Essentials, Italic, Paragraph, Undo, Heading, Font, Alignment } from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";

type RichTextEditorProps = {
  initialData: string;
  onChange: (data: string) => void;
  isReadOnly?: boolean;
};

const RichTextEditor = (props: RichTextEditorProps) => {
  const { initialData, onChange, isReadOnly } = props;

  return (
    <CKEditor
      disabled={isReadOnly}
      editor={ClassicEditor}
      onChange={(event, editor) => {
        const editorData = editor.getData();
        onChange(editorData);
      }}
      config={{
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "bold",
            "italic",
            "fontSize",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
          ],
        },
        plugins: [Bold, Essentials, Italic, Paragraph, Undo, Heading, Font, Alignment],
        initialData: initialData,
      }}
      onReady={(editor) => {
        const toolbarElement = editor.ui.view.toolbar.element;
        if (toolbarElement === null) return;

        if (isReadOnly) {
          toolbarElement.style.display = "none";
        }

        editor.on("change:isReadOnly", (evt, propertyName, isReadOnly) => {
          if (isReadOnly) {
            toolbarElement.style.display = "none";
          } else {
            toolbarElement.style.display = "flex";
          }
        });
      }}
    />
  );
};

export default RichTextEditor;
