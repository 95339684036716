import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import IconButton from "components/blocks/IconButton";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const PreviousRecords = () => {
  const { t } = useTranslation("Orders");

  const data = [
    {
      recordNumber: "رقم السجل",
      date: new Date(),
      members: "الأعضاء",
      recordDetails: "تفاصيل السجل",
      status: "Completed",
    },
    {
      recordNumber: "رقم السجل",
      date: new Date(),
      members: "الأعضاء",
      recordDetails: "تفاصيل السجل",
      status: "Completed",
    },
    {
      recordNumber: "رقم السجل",
      date: new Date(),
      members: "الأعضاء",
      recordDetails: "تفاصيل السجل",
      status: "pending",
    },
  ];

  const previousRecordTableHeaders = useMemo<GridSchema[]>(() => {
    return [
      {
        accessor: "recordNumber",
        field: "recordNumber",
        displayName: t("recordNumber"),
      },
      {
        accessor: "date",
        field: "date",
        displayName: t("date"),
        render: (row) => <>{new Date(row.date).toLocaleDateString()}</>, // TODO: ask about the date format
      },
      {
        accessor: "members",
        field: "members",
        displayName: t("members"),
      },
      {
        accessor: "recordDetails",
        field: "recordDetails",
        displayName: t("recordDetails"),
      },
      {
        accessor: "files",
        field: "files",
        displayName: t("files"),
        render(row) {
          return (
            <div className="d-flex ">
              <IconButton icon="icon-view" variant="transparent" innerIconColor="primary" onClick={() => {}} />
            </div>
          );
        },
      },
    ];
  }, [t]);

  return <Grid gridSchema={previousRecordTableHeaders} data={data} />;
};

export default PreviousRecords;
