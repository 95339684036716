import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import LookupDropdown from "components/blocks/LookupDropdown";
import RangeInput from "components/blocks/RangeInput";
import { CreateProgramRequest } from "../program.schema";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type FinancingItem = CreateProgramRequest["financingCalculationItems"][0];

type FinancingCalculationItemTemplateProps = {
  onAdd: (item: FinancingItem) => void;
};

const FinancingCalculationItemTemplate: React.FC<FinancingCalculationItemTemplateProps> = ({ onAdd }) => {
  const { t } = useTranslation("Programs");
  const [itemData, setItemData] = useState<FinancingItem>({
    calculationWay: "",
    financingTypeId: 0,
    fromValue: 0,
    toValue: 0,
    notes: "",
  });

  const handleOnChange = (key: keyof FinancingItem, value: string | number) => {
    setItemData({ ...itemData, [key]: value });
  };

  const onSubmit = () => {
    onAdd(itemData);
    setItemData({
      calculationWay: "",
      financingTypeId: 0,
      fromValue: 0,
      toValue: 0,
      notes: "",
    });
  };

  return (
    <>
      <div className="d-flex gap-4 align-items-end mb-3">
        <div className="row flex-grow-1">
          <LookupDropdown
            label={t("fundingType")}
            wrapperClassName="col-4"
            service="lookupService"
            endpoint="getAllFinancingTypes"
            textValueKey="financingTypesDetail.displayName"
            idValueKey="id"
            value={itemData.financingTypeId}
            onChange={(value) => handleOnChange("financingTypeId", +value)}
          />
          <FormInput
            wrapperClassName="col-4"
            label={itemData.calculationWay ? t("calculationMethod") : t("Common:notes")}
            value={itemData.calculationWay ? itemData.calculationWay : itemData.notes!}
            onChange={(e) => handleOnChange(itemData.calculationWay ? "calculationWay" : "notes", e.target.value)}
          />
          <RangeInput
            wrapperClassName="col-4"
            label={t("fundingAmountFromTo")}
            leftElement={<span className="text-primary">{t("Common:riyal")}</span>}
            placeholderStart={t("Common:from")}
            placeholderEnd={t("Common:to")}
            value={[itemData.fromValue.toString(), itemData.toValue.toString()]}
            onChange={(value) => {
              handleOnChange("fromValue", +value[0]);
              handleOnChange("toValue", +value[1]);
            }}
          />
        </div>
        <IconButton size="xl" variant="primary" icon="icon-plus" onClick={onSubmit} />
      </div>
    </>
  );
};

export default FinancingCalculationItemTemplate;
