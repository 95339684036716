import "./AttachmentCard.scss";
type Attachment = {
  id: number;
  fileName: string;
  size: string;
  extension: string;
};

const extensionsMapper = {
  pdf: "pdf",
  doc: "doc",
  docx: "doc",
};

const AttachmentCard = ({ attachment }: { attachment: Attachment }) => {
  return (
    <div key={attachment.id} className="attachment-card  rounded-4 p-3 d-flex gap-2 flex-column align-items-center">
      {/* TODO: fix icon size and shadow */}
      <i className={`fs-1 icon-${extensionsMapper[attachment.extension as keyof typeof extensionsMapper]}-big`} />
      <div className="d-flex flex-column align-items-center">
        <div className="fs-6 fw-bold ">{attachment.fileName}</div>
        <span className="mx-2 fs-12px text-gray">{attachment.size}</span>
      </div>
    </div>
  );
};

export default AttachmentCard;
