import { Modal as BootstrapModal } from "react-bootstrap";

type ModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  title?: string;
  footer?: React.ReactNode;
  size?: "sm" | "lg" | "xl";
  fullscreen?: boolean;
};

const Modal: React.FC<ModalProps> = (props) => {
  const { isOpen, onClose, children, title, footer, size, fullscreen } = props;

  return (
    <BootstrapModal
      fullscreen={fullscreen ? true : undefined}
      size={size}
      show={isOpen}
      onHide={onClose}
      contentClassName="p-4"
    >
      <div className="d-flex justify-space-between" style={{ maxHeight: "100%" }}>
        <h4 className="modal-title flex-1">{title}</h4>
        <button type="button" className="btn-close m-0" onClick={onClose} />
      </div>
      <div className="my-4 overflow-y-auto overflow-x-hidden flex-grow-1">{children}</div>
      {footer && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
};

export default Modal;
