import { LookupService } from "services/lookupService";
import Dropdown, { DropdownItemType, DropdownProps } from "./Dropdown";
import { useEffect, useState } from "react";
import { BaseResponseType } from "services/shared-models/sharedTypes";
import { BackendServices } from "services";

type LookupDropdownProps<T extends keyof typeof BackendServices> = Omit<DropdownProps, "data"> & {
  service: T;
  endpoint: keyof (typeof BackendServices)[T];
  onError?: (error: any) => void;
  textValueKey: string;
  idValueKey: string;
  query?: Record<string, any>;
  isPaginated?: boolean;
  disabled?: boolean;
  formProps?: any;
};

const access = (path: string, object: any) => path.split(".").reduce((o, i) => o[i], object);

function LookupDropdown<T extends keyof typeof BackendServices>(props: LookupDropdownProps<T>) {
  const { endpoint, query, textValueKey, idValueKey, onError, service, isPaginated, disabled, ...rest } = props;
  const [data, setData] = useState<DropdownItemType[]>([]);

  const queryParams = isPaginated ? { ...query, pageIndex: 1, pageSize: 10 } : query;

  useEffect(() => {
    if (disabled || !endpoint || !service) return;

    (BackendServices[service][endpoint] as any)(queryParams)
      .then((res: BaseResponseType<any>) => {
        if (!res.hasError) {
          const responseData = isPaginated ? res.data.result : res.data;
          setData(
            responseData.map((item: any) => ({
              id: access(idValueKey, item),
              value: access(textValueKey, item),
            })),
          );
        } else {
          onError?.(res.description);
        }
      })
      .catch((error: any) => {
        onError?.(error);
      });
  }, [endpoint, onError, query]);

  return <Dropdown data={data} disabled={disabled} {...rest} />;
}

export default LookupDropdown;
