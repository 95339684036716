import { Navigate, Outlet } from "react-router-dom";
import { useCookieContext } from "../../contexts";
import Cookies from "js-cookie";
import {useEffect} from "react";

function AccountLayout() {
  const { userSession } = useCookieContext();
  if (userSession || Cookies.get("Session")) {
    return <Navigate to={"/home"} replace />;
  } else
    return (
      <div className={"text-darkGray"}>
        <Outlet />
      </div>
    );
}

export default AccountLayout;
