import { Routes, Route, Navigate } from "react-router-dom";
import NotFoundLayout from "../../views/notfound/NotFoundLayout";

import Cookies from "js-cookie";
import { RouteItemConfig } from "../../routes/models";
import {useCookieContext} from "../../contexts";
import {useEffect} from "react";

interface SwitchRouteProps {
  routes: Array<RouteItemConfig>;
}

export const SwitchRoute: React.FC<SwitchRouteProps> = ({ routes }) => {
  const {userSession} = useCookieContext();
  const getRecursion = (value: RouteItemConfig): React.ReactNode[] => {
    const nestedRoutes: React.ReactNode[] = [];

    // If there are sub-menu items, recursively call getRecursion
    if (value.subMenu && value.subMenu.length > 0) {
      // Add a redirect route to the first sub-menu path
      nestedRoutes.push(
        <Route
          path={value.path}
          element={<Navigate to={value.subMenu[0].path} replace />}
          key={`redirectRoute${value.path}`}
        />,
      );

      // Recursively generate nested routes for each sub-menu item
      value.subMenu.forEach((subValue) => {
        nestedRoutes.push(...getRecursion(subValue));
      });

      // Wrap nested routes under the main route with layout component
      return [
        <Route path={value.path} element={<value.component />} key={`layoutRoute${value.path}`}>
          {nestedRoutes}
        </Route>,
      ];
    } else {
      // Generate a unique key for each route
      const key = Math.floor(Math.random() * 1000);

      // Check if the route requires authorization
      if (value.authorize) {
        nestedRoutes.push(
          Cookies.get("Session") || userSession? (
            <Route path={value.path} element={<value.component />} key={`privateRoute${key}`} />
          ) : (
            <Route path={value.path} element={<Navigate to="/account" replace />} key={`redirectRoute${key}`} />
          ),
        );
      } else {
        nestedRoutes.push(<Route path={value.path} element={<value.component />} key={`publicRoute${key}`} />);
      }

      return nestedRoutes;
    }
  };

  const renderDefaultRoute = () => {
    if (Cookies.get("Session") || userSession) {
      return <Route path="/" element={<Navigate to="/home" replace />} key={`defaultPrivateRoute`} />;
    } else {
      return <Route path="/" element={<Navigate to="/account" replace />} key={`defaultPublicRoute`} />;
    }
  };

  return (
    <>
      {routes.length > 0 && (
        <Routes>
          {renderDefaultRoute()}
          {routes.map((routeItem) => {
            return getRecursion(routeItem);
          })}
          <Route path="*" element={<NotFoundLayout />} />
        </Routes>
      )}
    </>
  );
};
