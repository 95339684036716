import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import FormInput from "components/blocks/FormInput";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./Procedures.scss";
import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";

type ProcedureType = {
  data: {
    notes: string;
    phase: string;
    action: string;
    userName: string;
    date: Date;
    isActive: boolean;
    isCompleted: boolean;
  }[];
  notes: string; // TODO: is it part of the submission data?
};

const Procedures = () => {
  const { t, i18n } = useTranslation("Orders");

  const procedures: ProcedureType = {
    data: [
      {
        notes: "الملاحظات",
        phase: "التفديم على البرنامج",
        action: "ارسال",
        userName: "اسم المستخدم",
        date: new Date(),
        isActive: false,
        isCompleted: true,
      },
      {
        notes: "الملاحظات",
        phase: "تعيين فاحصين",
        action: "الدور",
        userName: "اسم المستخدم",
        date: new Date(),
        isActive: true,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: new Date(),
        isActive: false,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: new Date(),
        isActive: false,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: new Date(),
        isActive: false,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: new Date(),
        isActive: false,
        isCompleted: false,
      },
      {
        notes: "الملاحظات",
        phase: "تقييم طلب",
        action: "مدير مركز",
        userName: "اسم المستخدم",
        date: new Date(),
        isActive: false,
        isCompleted: false,
      },
    ],
    notes: "notes",
  };

  const proceduresTableHeaders = useMemo<GridSchema[]>(() => {
    return [
      {
        accessor: "notes",
        field: "notes",
        displayName: t("notes"),
      },
      {
        accessor: "phase",
        field: "phase",
        displayName: t("phase"),
      },
      {
        accessor: "action",
        field: "action",
        displayName: t("action"),
      },
      {
        accessor: "userName",
        field: "userName",
        displayName: t("userName"),
      },
      {
        accessor: "date",
        field: "date",
        displayName: t("date"),
        render: (row) => {
          return <>{new Date(row.date).toLocaleDateString()}</>;
        },
      },
    ];
  }, [t]);

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("procedures")} elementId={3}>
        <div className="pb-2">
          <Grid gridSchema={proceduresTableHeaders} data={procedures.data} />
          <FormInput placeholder={t("notes")} label={t("notes")} />

          <div className="action-stepper gap-1">
            {procedures.data.map((procedure, i) => {
              return (
                <div className="step-container d-flex align-items-center ">
                  <div className="step gap-2" key={i}>
                    <div
                      className={`status-circle ${procedure.isActive ? "active" : ""} ${
                        procedure.isCompleted ? "completed" : ""
                      }`}
                    ></div>

                    <div>
                      <div className="fs-14px fw-bold">
                        {procedure.phase}
                        <span className="text-primary fs-12px"> ({t("action")})</span>
                        {/* TODO: ask about this */}
                      </div>
                      <div className="fs-12px text-gray">
                        {procedure.action} : {procedure.userName}
                      </div>
                      <div className="fs-12px text-gray">{moment(procedure.date).format("DD/MM/YYYY hh:mm:ss")}</div>
                    </div>
                  </div>
                  {i === procedures.data.length - 1 ? null : (
                    <div className={`mx-3 text-gray ${i18n.language === "en" ? "rotate-icon" : ""}`}>
                      <i className="icon-arrow-left-with-tile "></i>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default Procedures;
