import { useTranslation } from "react-i18next";
import TextBoxFilter from "./TextInputFilter";
import { GridFilterProps } from "components/GridView/GridView.types";
import DatePicker from "components/blocks/DatePicker";
import useGridStore from "components/GridView/grid.store";
import { useShallow } from "zustand/react/shallow";
import Dropdown from "components/blocks/Dropdown";
import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";

// TODO: Pending implementations
// - Get/Set filter values from/to store
// - Implement the filter change functionality
// - Implement the reset functionality

const GridFilter: React.FC<GridFilterProps> = ({ gridSchema }) => {
  const { t } = useTranslation("Common");
  const { modelFilterPined, toggleModelFilterPin, modelFilterVisible } = useGridStore(
    useShallow((state) => ({
      modelFilterPined: state.modelFilterPined,
      toggleModelFilterPin: state.toggleModelFilterPin,
      modelFilterVisible: state.modelFilterVisible,
    })),
  );

  const gridFilters = gridSchema
    .filter((schema) => schema.filterable)
    .map((schema) => ({
      field: schema.field,
      displayName: schema.displayName,
      inputType: schema.filterOptions?.type || "text",
      options: schema.filterOptions?.options || [],
    }));

  return (
    <div
      className={`w-100 my-3 d-flex justify-content-between align-items-end flex-wrap ${
        modelFilterPined || modelFilterVisible ? "" : "d-none"
      }`}
    >
      <div className="d-flex flex-wrap gap-3 align-items-end">
        {gridFilters.map((filter) => {
          if (filter.inputType === "text") {
            return <FormInput label={filter.displayName} value="" />;
          } else if (filter.inputType === "select") {
            return (
              <Dropdown
                wrapperClassName="flex-grow-1"
                label={filter.displayName}
                value=""
                data={filter.options}
                onChange={(value) => {
                  // TODO: post selection callback
                  console.log("dropdown selected", filter.field, value);
                }}
              />
            );
          } else if (filter.inputType === "date") {
            return (
              <DatePicker
                wrapperClassName="flex-grow-1"
                placeholder={filter.field}
                label={filter.displayName}
                value=""
                onChange={(date) => {
                  // TODO: post date selection callback
                  console.log("date selected", date, filter.field);
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className="form-group d-flex gap-2 align-items-end">
        <button type="button" className="btn btn-outline-primary p-2">
          {t("filterNow")}
        </button>
        <button type="button" className="btn btn-outline-danger p-2">
          {t("reset")}
        </button>
        <IconButton
          icon="icon-pin"
          onClick={toggleModelFilterPin}
          size="lg"
          variant={modelFilterPined ? "secondary" : "outline-secondary"}
        />
      </div>
      <input id="jsonData" name="jsonData" type="hidden" value="" />
    </div>
  );
};

export default GridFilter;
